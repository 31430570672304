@import '/assets/css/theme.css';
@import '/assets/css/plugins.css';
@import '/assets/css/shared/layout.css';
@import '/assets/css/shared/layout_landing.css';
@import '/assets/css/shared/navbar.css';
@import '/assets/css/shared/footer.css';
@import './responsive.css';

.wrapper {
  height: 100%;
}

.section_wrap {
  min-height: 100%;
  justify-content: center;
}

.section_wrap .section_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  /* width: 400px; */
}

.section_layout_contact_layout,
.section_layout_error_layout {
  flex-direction: row;
  justify-content: space-between;
}

.section_layout_error_layout .title_main_2x {
  font-size: 7rem;
  font-weight: 600;
}
/* 
.section_layout_error_layout {
  flex-direction: column !important;
  align-items: center !important;
} */

.section_wrap .page-img {
  flex: 1;
  display: flex;
  justify-content: center;
}

.page-img img {
  width: 314px;
  max-width: 100%;
}

.section_wrap .titles_wrap {
  justify-content: center;
  width: 500px;
  order: 1;
}

/* .section_layout_error_layout .titles_wrap,
.section_layout_error_layout .titles_wrap .desc {
  width: 100% !important;
  text-align: center;
} */

.input-form {
  padding: 10px 0;
  /* width: 350px; */
}

.input-form .input-field {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.input-form label {
  display: none;
}

.input-form input,
.input-form textarea {
  padding: 10px;
  border-radius: 4px;
  background-color: var(--bg-color-form-input);
  border: 1px solid #e2e2e200;
  border-radius: 2px;
  color: var(--color-app-primary);
  font-weight: 500;
}

.input-form input::placeholder,
.input-form textarea::placeholder {
  font-weight: 400;
}
.input-form textarea:focus,
.input-form input:focus {
  color: var(--bg-color-faq-body);
  background-color: var(--color-app-primary);
}

#form_error {
  padding: 7px;
  color: #ff5f5f;
  font-size: 20px;
}
#form_error::first-letter {
  text-transform: capitalize;
}
#form_error:before {
  content: '⚠️';
}

#form_auth.inactive {
  margin: 0;
}
#form_auth.inactive .input-field,
#form_auth.inactive .cta_wrap {
  display: none;
  margin: 0;
}

#form_auth.inactive .always-active {
  display: flex;
}

.if-processing {
  display: none;
}

.processing .if-processing {
  display: unset;
}

.processing .if-not-processing {
  display: none;
}

.connect-btns {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  background: transparent;
  margin: 0 auto;
  margin-top: 20px;
  z-index: 1;
  width: 100%;
  flex: 1;
}

.connect-btns p {
  text-align: center;
  margin-bottom: 15px;
  opacity: 0.75;
  width: 100%;
}

.connect-btns button {
  height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 8px !important;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #fff;
  float: 1;
  position: relative;
  flex: 1;
  width: 100%;
  border: none;
  cursor: pointer;
}

.connect-btns button img {
  width: 22px;
}

.connect-btns button span {
  width: 80%;
  margin: 0 auto;
  font-size: 16px;
}

.connect-btns .connect-btn-twitch {
  background: #9146ff;
  background-color: #9146ff;
  color: #fff;
}
.connect-btns .connect-btn-twitch:hover {
  background-color: #6910ee;
}

.connect-btns .connect-btn-youtube {
  background-color: #e62117;
  color: #fff;
}

.connect-btns .connect-btn-youtube:hover {
  background-color: rgb(219, 18, 8);
}

.connect-btns .connect-btn-facebook {
  background-color: #1877f2;
  color: #fff;
}
.connect-btns .connect-btn-facebook:hover {
  background-color: #0e64d4;
}

.connect-btn-dlive {
  background-color: #ffd300;
  color: #000 !important;
}

.connect-btn-dlive:hover {
  background-color: #d4b209;
}

.spinner-wrap {
  position: relative;
  height: 175px;
  align-self: center;
}

.spinner-wrap .yt {
  background-color: #e62117;
}

.spinner-wrap .twitch {
  background-color: #9146ff;
}

.spinner-wrap .fb {
  background-color: #1877f2;
}

.spinner-wrap .dlive {
  background-color: #ffd300;
}

.oauth-appName {
  text-transform: capitalize;
}

.if-error {
  display: none;
}

.errored .if-error {
  display: unset;
}

.errored .if-not-error {
  display: none;
}

.ack-message {
  text-align: center !important;
}

.section_wrap .titles_wrap .desc {
  width: auto;
}
.contact-options-list-head {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 10px;
  opacity: 0.7;
}
.contact-options-list {
  padding-right: 5%;
  order: 0;
}

.contact-option-item {
  padding: 20px 10px;
  border-bottom: 1px solid var(--border-color-prod-compare-wrap);
  display: flex;
  align-items: flex-start;
}

.contact-option-item:last-of-type {
  border-bottom: none;
}

.contact-option-item img {
  width: 26px;
}

.option-item-icon {
  width: 45px;
  height: 45px;
  background-color: #fff;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  box-shadow: 0 4px 6px 0 rgba(12, 0, 46, 0.05);
  display: none;
}

.option-item-content {
  flex: 1;
}

.option-item-title {
  font-size: 1.4rem;
  margin-bottom: 12px;
  font-weight: 600;
}

.option-item-desc {
  font-size: 1.1rem;
}

.option-item-desc .kb {
  margin-top: 6px;
  font-size: 0.9rem;
  opacity: 0.7;
}

.contact-option-item label {
  font-size: 0.8rem;
  opacity: 0.85;
  text-transform: uppercase;
}

.input-form textarea {
  min-height: 175px;
}
